import { Select, Tooltip } from "antd";
import React, { useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  $isTextNode,
  $createTextNode,
  $isElementNode,
} from "lexical";

const fontOptions: string[] = [
  "Arial",
  "Times New Roman",
  "Helvetica",
  "Courier New",
  "Verdana",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Impact",
  "Comic Sans MS",
];

export default function FontSelect() {
  const [editor] = useLexicalComposerContext();
  const [fontFamilly, setFontFamilly] = useState<string>("Arial");

  const handleFontChange = (font: string) => {
    console.log("font", font); // Here the font logged is "Trebuchet MS"
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const nodes = selection.extract();
        nodes.forEach((node) => {
          if ($isTextNode(node)) {
            const style = node.getStyle();
            const hasFontSelected = /font-family/.test(style);
            if (hasFontSelected) {
              const newStyle = style.replace(
                /font-family:\s*[^;]+;/,
                `font-family: ${font};`
              );
              console.log("newStyle", newStyle); // here newStyle still haf font-family: Georgia. Why ?
              node.setStyle(newStyle);
            } else node.setStyle(style + ` font-family: ${font};`);
          } else if ($isElementNode(node)) {
            node.getChildren().forEach((childNode) => {
              if ($isTextNode(childNode) && childNode.getParent() === null) {
                const style = childNode.getStyle();
                const hasFontSize = /font-size/.test(style);
                if (hasFontSize) {
                  const newStyle = style.replace(
                    /font-family:\s*[^;]+;/,
                    `font-family: ${font};`
                  );
                  childNode.setStyle(newStyle);
                } else {
                  childNode.setStyle(style + ` font-family: ${font};`);
                }
              }
            });
          } else {
            const textContent = node.getTextContent();
            const textNode = $createTextNode(textContent);
            textNode.setStyle(` font-family: ${font};`);
            node.replace(textNode);
          }
        });
      }
    });
  };

  return (
    <Tooltip title="Police" mouseEnterDelay={0.5}>
      <Select
        style={{ width: 180 }}
        // onChange={handleFontChange}
        placeholder="Choisissez une police"
      >
        {fontOptions.map((font: string, key: number) => (
          <Select.Option
            key={font}
            value={font}
            onMouseDown={() => handleFontChange(font)}
          >
            <span style={{ fontFamily: font }}>{font}</span>
          </Select.Option>
        ))}
      </Select>
    </Tooltip>
  );
}
