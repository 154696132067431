import { useState, useRef, useContext, useEffect } from "react";
import {
  IMediaRecorder,
  MediaRecorder,
  register,
} from "extendable-media-recorder";
import { connect } from "extendable-media-recorder-wav-encoder";
import Context from "../context/Context";
import useLLM from "./useLLM";
import { useParams } from "react-router-dom";
import {
  ErrorOutput,
  Report,
  Report as ThianaReport,
} from "@thiana/api-thiana-client";
import { isChrome, isFirefox, isSafari } from "react-device-detect";
import { message, notification } from "antd";
import { BackendError } from "../types/BackendErrors";
import useReports from "./useReports";
import useTranscriptionsRecoveries from "./useTranscriptionsRecoveries";
let chunks: BlobPart[] = [];
(async () => {
  await register(await connect());
})();

const getNavigator = () => {
  if (isFirefox) return "firefox";
  else if (isSafari) return "safari";
  else if (isChrome) return "chrome";
  //@ts-ignore
  else if (navigator.brave) return "brave";
  else return "unknow";
};

interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;
}

type WebsocketMessage = {
  state: "streaming" | "initializing";
  validated?: number;
  in_validation?: number;
  fully_validated?: number;
  transcription?: string; // contient le texte généré par la transcription du back ia
  transcription_id?: string;
};

export default function useASR(props: Props) {
  let { reportID } = useParams();
  const [mediaStream, setMediaStream] = useState<MediaStream>();
  const speakerStream = useRef<MediaStream>();
  const micStream = useRef<MediaStream>();
  const isRecordingRef = useRef(false);
  const [liveResponse, setLiveResponse] = useState("");
  const liveResponseRef = useRef("");
  const liveResponseHistory = useRef<string[]>(["", ""]);
  const [animatedLiveResponse, setAnimatedLiveResponse] = useState("");
  const [fixedLiveResponse, setFixedLiveResponse] = useState("");
  const [validatedIndex, setValidatedIndex] = useState(0);
  const [inValidationIndex, setInValidationIndex] = useState(0);
  const nextRunLLM = useRef(false);
  const mediaRecorderRef = useRef<IMediaRecorder>();
  const transcriptionIDRef = useRef<string>("");
  const liveCommandRef = useRef<string>("");
  const [liveCommandHistory, setLiveCommandHistory] = useState<string[]>([
    "",
    "",
  ]);
  const liveCommandHistoryRef = useRef<string[]>(["", ""]);
  const { updateReport, createReport } = useReports({});

  const {
    addTranscriptionIntoLocalStorage,
    removeTranscriptionFromLocalStorage,
  } = useTranscriptionsRecoveries();

  const { startGeneration } = useLLM();
  // Context
  const {
    reports,
    updateReports,
    currentCommand,
    mode,
    currentReport,
    updateCurrentReport,
    updateIsASRProcessing,
    dispatchFlow,
    teleconsultation,
    updateCurrentCommand,
  } = useContext(Context);

  useEffect(() => {
    if (nextRunLLM.current && currentReport) {
      // On met à jour le flow pour dire que la prochaine étape c'est le lancement du LLM
      dispatchFlow({
        type: "SOCKET_LAUNCH_LLM",
      });
      startGeneration(currentReport, undefined, "conversation");
      nextRunLLM.current = false;
    }
    // Sinon, la prochaine étape est le stop de l'ASR
    else
      dispatchFlow({
        type: "SOCKET_ASR_STOP",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport?.transcription]);

  const startTranscription = async () => {
    console.log("startTranscription");
    const accessToken = localStorage.getItem("accessJWT");
    const refreshToken = localStorage.getItem("refreshJWT");
    isRecordingRef.current = true;
    updateIsASRProcessing(true);
    if (accessToken && refreshToken) {
      // We start microphone
      startRecording();
      try {
        connectWebsockets();

        props.SOCKET_ASR.current?.addEventListener("close", async (event) => {
          let transcription = liveResponseRef.current;
          console.log("close");
          removeTranscriptionFromLocalStorage(transcriptionIDRef.current);
          stopRecording();
          // Dans le cas d'une création de report
          if (!reportID && mode) {
            let reportCreated = await createReport({
              transcription: transcription,
              generation: currentReport?.generation,
              title: currentReport?.title,
              transcriptions: [{ id: transcriptionIDRef.current }],
            });
            // Si le report a bien été crée
            if (reportCreated) {
              // On met à jour le currentReport
              updateCurrentReport(reportCreated);
              // // On update la liste des reports
              let tmpReports = [...reports];
              tmpReports.unshift(reportCreated);
              updateReports(tmpReports);
            }
          }
          // Dans le cas d'une mise à jour de report
          else {
            let updatedReport: ThianaReport = {
              ...currentReport,
              transcription: currentReport?.transcription + transcription,
              // @ts-ignore
              transcriptions: [{ id: transcriptionIDRef.current }],
            };
            // On met à jour le currentReport
            updateCurrentReport(updatedReport);
            // On met à jour le report en bdd avec la transcription reçu
            updateReport(updatedReport);
          }
          if (nextRunLLM.current) {
            dispatchFlow({
              type: "SOCKET_LAUNCH_LLM",
            });
            startGeneration(currentReport as Report, undefined, "conversation");
          } else
            dispatchFlow({
              type: "SOCKET_ASR_STOP",
            });
          resetTranscription();
        });

        // Écouter les messages entrants venant du backend
        props.SOCKET_ASR.current?.addEventListener(
          "message",
          function (event: MessageEvent<string>) {
            try {
              let data: any = JSON.parse(event.data);
              // Si il y a des erreurs..
              if (data.errors) {
                let errorOutput: ErrorOutput = JSON.parse(event.data);
                // Exception => On ne traitre pas l'erreur : "rpc error: code = Internal desc = SendMsg called after CloseSend" comme une erreur
                if (
                  !errorOutput.errors[0].message.includes(
                    "rpc error: code = Internal desc = SendMsg called after CloseSend"
                  )
                ) {
                  notification.error({
                    message: "Erreur",
                    description:
                      "Une erreur est survenue lors de la transcription.",
                  });
                }
                errorOutput.errors?.forEach((error: BackendError) => {
                  console.error(error.message);
                });
              }
              // Si il n'y a pas d'erreur...
              else {
                let websocketMessage: WebsocketMessage = JSON.parse(event.data);
                switch (data.state) {
                  /**
                   *  STREAMING STATE
                   * Réception et traitement du texte renvoyé par le backend
                   */
                  case "streaming":
                    if (
                      websocketMessage.in_validation !== undefined &&
                      websocketMessage.validated !== undefined &&
                      websocketMessage.transcription !== undefined
                    ) {
                      setInValidationIndex(websocketMessage.in_validation);
                      setValidatedIndex(websocketMessage.validated);
                      liveResponseRef.current = websocketMessage.transcription;
                      liveResponseHistory.current.shift();
                      liveResponseHistory.current.push(
                        websocketMessage.transcription.substring(1)
                      );
                      // set animated live response : slice du retour de la socket
                      let animatedText = websocketMessage.transcription.slice(
                        liveResponseHistory.current[0].length,
                        liveResponseHistory.current[1].length
                      );
                      // set fixed live response
                      let fixedText = websocketMessage.transcription.slice(
                        0,
                        liveResponseHistory.current[0].length
                      );
                      dispatchFlow({
                        type: "SOCKET_ASR_FEEDBACK",
                        payload: {
                          fixedLiveResponse: fixedText,
                          animatedLiveResponse: animatedText,
                          validationIndices: {
                            inValidationIndex: websocketMessage.in_validation,
                            validatedIndex: websocketMessage.validated,
                          },
                        },
                      });
                      setAnimatedLiveResponse(animatedText);
                      setFixedLiveResponse(fixedText);
                    }
                    break;
                  /**
                   *  INITIALIZING STATE : Réception de l'id de la transcription créée
                   *  Si la transcription tombe en erreur, l'id de la transcription restera dans le localStorage pour être recover plus tard
                   */
                  case "initializing":
                    if (websocketMessage.transcription_id) {
                      transcriptionIDRef.current =
                        websocketMessage.transcription_id;
                      addTranscriptionIntoLocalStorage(
                        websocketMessage.transcription_id,
                        mode ? mode : "conversation",
                        currentReport?.id
                      );
                    }
                    break;
                  default:
                    break;
                }
              }
            } catch (e) {
              console.error(e);
            }
          }
        );
      } catch (error) {
        stopRecording();
        console.error(error);
      }
    }
  };

  const connectWebsockets = async () => {
    const accessToken = localStorage.getItem("accessJWT");
    const refreshToken = localStorage.getItem("refreshJWT");
    // Créer une connexion WebSocket
    props.SOCKET_ASR.current = new WebSocket(
      "wss://" + process.env.REACT_APP_URL_BACKAPP_WS + "/ws/v1/transcriptions",
      ["json", accessToken as string, refreshToken as string]
    );

    props.SOCKET_ASR.current.addEventListener("error", (event) => {
      stopRecording();
      message.error("Une erreur est survenue lors de la transcription.");
    });

    props.SOCKET_ASR.current.addEventListener("open", (event) => {
      console.log("open");
    });
  };

  // Cette fonction démarre l'enregistrement des données audios du micro
  const startRecording = async (statement = false) => {
    try {
      // On crée un context
      const audioContext = new AudioContext(
        isFirefox || isSafari ? undefined : { sampleRate: 16000 }
      );
      micStream.current = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
        },
      });
      // On crée un sourceNode

      const micSource = audioContext.createMediaStreamSource(micStream.current);

      // Nouvelle destination avec l'audio context
      const mediaStreamAudioDestinationNode =
        new MediaStreamAudioDestinationNode(
          audioContext,
          isFirefox || isSafari
            ? undefined
            : {
                channelCount: 1,
                channelCountMode: "explicit",
                channelInterpretation: "speakers",
              }
        );
      micSource.connect(mediaStreamAudioDestinationNode);

      let speakerSource: MediaStreamAudioSourceNode;

      if (teleconsultation && !statement) {
        speakerStream.current = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: {
            echoCancellation: true,
          },
        });

        speakerStream.current.getVideoTracks().forEach((track) => track.stop());

        speakerSource = audioContext.createMediaStreamSource(
          speakerStream.current
        );

        speakerSource.connect(mediaStreamAudioDestinationNode);
      }

      // On crée un mediaRecorder en wav
      const mediaRecorder = new MediaRecorder(
        mediaStreamAudioDestinationNode.stream,
        {
          mimeType: "audio/wav",
          audioBitsPerSecond: isFirefox || isSafari ? 768000 : 256000,
        }
      );
      setMediaStream(mediaStreamAudioDestinationNode.stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.addEventListener("onstop", (event) => {
        micSource.disconnect();
        speakerSource.disconnect();
        setLiveCommandHistory(["", ""]);
        micStream.current?.getTracks().forEach((track) => track.stop());
        speakerStream.current?.getTracks().forEach((track) => track.stop());
        console.log("mediaRecorder stop");
      });

      mediaRecorder.addEventListener("dataavailable", (event) => {
        console.log("dataavailable");
        chunks.push(event.data);
        var reader = new FileReader();
        var blob = event.data;
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let payload: any = {
            state: "streaming",
            audio: reader.result,
            metadata: {
              mode: "dictation",
              report_id: reportID ? reportID : null, // UUID ou undefined
              navigator: getNavigator(),
              channel_count:
                isFirefox || isSafari
                  ? 2
                  : mediaStreamAudioDestinationNode.channelCount,
              sample_rate: audioContext.sampleRate,
            },
          };
          if (isRecordingRef.current) {
            // Sinon on envoie que l'audio et le state
            props.SOCKET_ASR.current?.send(JSON.stringify(payload));
          }
        };
      });
      mediaRecorder.start(500);
      isRecordingRef.current = true;
    } catch (error) {
      console.error(error);
      stopRecording();
    }
  };
  // Cette fonction met fin à l'enregistrement de micro, et coupe la websocket en envoyant un évènement 'stop'
  const stopRecording = async (toLLM = false) => {
    console.log("stopRecording");
    if (toLLM === true) nextRunLLM.current = true;
    mediaRecorderRef.current?.stop(); // On coupe le micro
    mediaStream?.getTracks().forEach((track) => track.stop()); // On coupe le micro
    micStream.current?.getTracks().forEach((track) => track.stop());
    speakerStream.current?.getTracks().forEach((track) => track.stop());
    setTimeout(() => {
      props.SOCKET_ASR.current?.send(JSON.stringify({ state: "ending" })); // Avec ce message, le backend coupera la websocket.
    }, 500);
  };

  const resetTranscription = () => {
    dispatchFlow({
      type: "SOCKET_ASR_STOP",
    });
    setValidatedIndex(0);
    setInValidationIndex(0);
    updateIsASRProcessing(false);
    isRecordingRef.current = false;
    liveResponseRef.current = "";
    setLiveResponse("");
  };

  const startDictation = async () => {
    const accessToken = localStorage.getItem("accessJWT");
    const refreshToken = localStorage.getItem("refreshJWT");
    isRecordingRef.current = true;
    updateIsASRProcessing(true);
    let currentCommandCache = currentCommand.slice();
    if (accessToken && refreshToken) {
      // We start microphone
      startRecording(true);
      try {
        connectWebsockets();

        props.SOCKET_ASR.current?.addEventListener(
          "message",
          function (event: MessageEvent<string>) {
            let data: any = JSON.parse(event.data);
            if (data.transcription !== undefined) {
              const newLiveCommandHistory = [...liveCommandHistoryRef.current];
              liveCommandRef.current = data.transcription;
              newLiveCommandHistory.shift();
              newLiveCommandHistory.push(data.transcription);
              liveCommandHistoryRef.current = newLiveCommandHistory;
              setLiveCommandHistory(newLiveCommandHistory);
            }
            // updateCurrentCommand(
            //   currentCommandCache !== ""
            //     ? data.transcription
            //     : currentCommandCache + " " + data.transcription
            // );
            console.log(data.transcription);
          }
        );

        props.SOCKET_ASR.current?.addEventListener("close", async (event) => {
          console.log(event);
          updateCurrentCommand(currentCommand + liveCommandRef.current);
          updateIsASRProcessing(false);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return {
    startTranscription,
    stopRecording,
    liveResponse,
    isRecordingRef,
    animatedLiveResponse,
    fixedLiveResponse,
    validatedIndex,
    inValidationIndex,
    startDictation,
    liveCommandHistory,
    liveCommandRef,
  };
}
