import { RiseOutlined } from "@ant-design/icons";
import { useContext, useState, useEffect } from "react";
import Context from "../../../context/Context";
import { Button, Flex, Modal, Tag, Tooltip, Typography } from "antd";
import { getFormattedACL } from "../../../utils/security";
import { AuthApi } from "@thiana/api-thiana-client";
import { configuration } from "../../../api-configuration/Configuration";
import PaymentModalContent from "./payment/PaymentModalContent";
const stripeURL = process.env.REACT_APP_URL_STRIPE;

const stripeCheckoutURL = "";

export default function Subscribe() {
  const [hover, serHover] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const authAPI = new AuthApi(configuration);
  const { subscription, updateSubscription } = useContext(Context);

  useEffect(() => {
    (async () => {
      let data = await authAPI.authUserMe();
      updateSubscription(data.data[0].subscription);
    })();
  }, []);

  const paymentAction = () => {
    switch (subscription?.status) {
      case "active":
        window.open(stripeURL, "_blank");
        break;
      default:
        setIsPaymentModalOpen(true);
    }
  };

  const renderPaymentInfos = () => {
    switch (subscription?.status) {
      case "trialing":
        return (
          <>Période d'essai jusqu'au {subscription.trial_until.split(" ")[0]}</>
        );
      case "canceled":
        return <>Aucun abonnement</>;
      case "active":
        if (subscription.payment_level === "assistant essential") {
          return (
            <Tooltip title="Cliquez pour gérer l'abonnement">
              Abonnement offre Essentielle jusqu'au{" "}
              {subscription.checked_until.split(" ")[0]}
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title="Cliquez pour gérer l'abonnement">
              Abonnement offre Intégrale jusqu'au{" "}
              {subscription.checked_until.split(" ")[0]}
            </Tooltip>
          );
        }
      default:
        break;
    }
  };

  return (
    <>
      <Flex
        onMouseEnter={() => serHover(true)}
        onMouseLeave={() => serHover(false)}
        style={{
          padding: 8,
          borderRadius: 8,
          backgroundColor: hover ? "rgba(0,0,0,0.3)" : "",
          color: hover ? "white" : "",
          cursor: "pointer",
        }}
        onClick={() => paymentAction()}
      >
        {renderPaymentInfos()}
      </Flex>
      <Modal
        title="Choisissez une offre"
        open={isPaymentModalOpen}
        onCancel={() => setIsPaymentModalOpen(false)}
        footer={[
          <Button onClick={() => setIsPaymentModalOpen(false)}>Annuler</Button>,
        ]}
        width={1200}
      >
        <PaymentModalContent />
      </Modal>
    </>
  );
}
