import { ClassifiedReports } from "../types/Reports";
import { Report as ThianaReport } from "@thiana/api-thiana-client";

function parseDate(dateString: string) {
  const [datePart, timePart] = dateString.split(" ");
  const [day, month, year] = datePart.split("/").map(Number);
  const [hours, minutes, seconds] = timePart.split(":").map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
}

export default function classifyReports(
  reports: ThianaReport[]
): ClassifiedReports {
  const groupedReports: { [key: string]: ThianaReport[] } = {};

  reports.forEach((report) => {
    const createdAt = parseDate(report.created_at);
    const now = new Date();
    let timePeriod: string;

    if (createdAt.toDateString() === now.toDateString()) {
      timePeriod = "Aujourd'hui";
    } else if (
      createdAt > new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
    ) {
      timePeriod = "Hier";
    } else if (
      createdAt > new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)
    ) {
      timePeriod = "Les 7 derniers jours";
    } else if (
      createdAt >
      new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30)
    ) {
      timePeriod = "Les 30 derniers jours";
    } else {
      const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      timePeriod =
        monthNames[createdAt.getMonth()] + " " + createdAt.getFullYear();
    }
    if (!groupedReports[timePeriod]) {
      groupedReports[timePeriod] = [];
    }

    groupedReports[timePeriod].push(report);
  });
  return groupedReports;
}
