import {
  AuthApi,
  Configuration,
  ResponseContext,
  RequestContext,
  UsersApi,
  ReportsApi,
  ReportSnapshotsApi,
  StoragesApi,
  // ProfessionalApi,
  ProfessionalsApi,
  ReportsProcessingApi,
  TemplateReportsApi,
} from "@thiana/api-thiana-client";
import { postMiddleware } from "./PostMiddleware";
import { preMiddleware } from "./PreMiddleware";

// API configuration
export const configuration = new Configuration({
  basePath: process.env.REACT_APP_URL_BACKAPP_CLIENT,
  middleware: [
    {
      // function called before the request
      pre: async (context: RequestContext) => {
        await preMiddleware(context);
      },
      // function called on the response
      post: async (context: ResponseContext) => {
        await postMiddleware(context);
      },
    },
  ],
});

// Services
export const apiAuth = new AuthApi(configuration);
export const apiUsers = new UsersApi(configuration);
// export const apiProfessional = new ProfessionalApi(configuration);
export const apiProfessionals = new ProfessionalsApi(configuration);
export const apiReports = new ReportsApi(configuration);
export const apiReportSnapshots = new ReportSnapshotsApi(configuration);
export const apiTemplateReports = new TemplateReportsApi(configuration);
export const apiStorages = new StoragesApi(configuration);
export const apiProcessings = new ReportsProcessingApi(configuration);
