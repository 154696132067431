import React, { useContext, useState, useEffect, useCallback } from "react";
import Context from "../../../../../context/Context";
import { Button, Divider, Flex, Select } from "antd";
import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
} from "@ant-design/icons";
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import BasicFormatingCommands from "./toolbar-components/BasicFormatingCommands";
import TextAlignmentCommands from "./toolbar-components/TextAlignmentCommands";
import FontSizeSelect from "./toolbar-components/FontSizeSelect";
import FontSelect from "./toolbar-components/FontSelect";
import ListCommands from "./toolbar-components/ListCommands";
import ColorSelect from "./toolbar-components/ColorSelect";

export default function Toolbar() {
  const { theme } = useContext(Context);

  return (
    <Flex
      style={{
        borderBottom: "1px solid " + theme.sideBackgroundColor,
        flexWrap: "wrap",
      }}
    >
      <Flex style={{ padding: 4 }}>
        <BasicFormatingCommands />
      </Flex>
      {/* <Flex>
        <Divider type="vertical" style={{ height: "100%" }} />
      </Flex> */}

      <Flex style={{ padding: 4 }}>
        <TextAlignmentCommands />
      </Flex>

      {/* <Flex>
        <Divider type="vertical" style={{ height: "100%" }} />
      </Flex> */}
      <Flex style={{ padding: 4 }}>
        <ListCommands />
      </Flex>
      <Flex style={{ padding: 4 }}>
        {/* <Divider type="vertical" /> */}

        <FontSizeSelect />
      </Flex>
      <Flex style={{ padding: 4 }}>
        <FontSelect />
      </Flex>
      <Flex style={{ padding: 4 }}>
        <ColorSelect />
      </Flex>
    </Flex>
  );
}
