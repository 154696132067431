import { notification } from "antd";
import { BackendError } from "../types/BackendErrors";
import { errorWithCapitalization } from "../utils/errors";
import { ResponseContext } from "@thiana/api-thiana-client";

/**
 * Ce middleware permet de récupérer (et stocker dans le localStorage) les nouveaux tokens si la requête revient en success
 * Si la reqûete revient en erreur 401, on déconnecte le user via la route /logout
 * @param response réponse de la requête
 * @returns
 */
export const postMiddleware = async (context: ResponseContext) => {
  // Success
  if (context.response.status >= 200 && context.response.status < 300) {
    let accessToken = context.response.headers.get("X-Access-Token");
    let refreshToken = context.response.headers.get("X-Refresh-Token");
    if (accessToken) localStorage.setItem("accessJWT", accessToken);
    if (refreshToken) localStorage.setItem("refreshJWT", refreshToken);
  }
  // Erreur
  else {
    // Si erreur 401 Unauthorized => logout
    if (context.response.status === 401) window.location.replace("/logout");
    // Sinon on affiche juste l'erreur renvoyé par le back
    let errorsResponse = await context.response.json();
    errorsResponse.errors?.forEach((error: BackendError) => {
      notification.error({
        message: "Erreur",
        description: errorWithCapitalization(error.message),
      });
    });
  }
  return context;
};
