import { useNavigate } from "react-router-dom";
import { getTemplates, postTemplate } from "../api/api";
import { Template, TemplatesResponse } from "../types/Templates";
import { EMPTY_UUID } from "../utils/constants";

interface Props {
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
}

export default function useTemplates(props: Props) {
  let navigate = useNavigate();

  const createNewTemplate = async (data: {
    title: string;
    content: string;
  }) => {
    let templatesResponse: TemplatesResponse;

    let response = await postTemplate({
      title: data.title,
      content: data.content,
    });
    let status = response.status;
    if (response.status === 201) {
      getAllTemplates();
      templatesResponse = await response.json();
      if (templatesResponse.data)
        navigate("/templates/" + templatesResponse.data[0].id);
    }
    return status;
  };

  const createNewTemplateVersion = async (
    currentTemplate: Template | undefined
  ) => {
    let templatesResponse: TemplatesResponse;
    let response = await postTemplate({
      title: currentTemplate?.title,
      content: currentTemplate?.content,
      parent_id:
        currentTemplate?.parent_id !== EMPTY_UUID
          ? currentTemplate?.parent_id
          : currentTemplate?.id,
    });
    if (response.status === 201) {
      getAllTemplates();
      templatesResponse = await response.json();
      if (templatesResponse.data)
        navigate("/templates/" + templatesResponse.data[0].id);
    }
    return response.status;
  };

  const getAllTemplates = async (items: number = 100, page: number = 1) => {
    let response = await getTemplates(items, page);
    if (response.status === 200) {
      let templatesResponse: TemplatesResponse;
      templatesResponse = await response.json();
      if (templatesResponse.data) props.setTemplates(templatesResponse.data);
    }
  };

  return { getAllTemplates, createNewTemplate, createNewTemplateVersion };
}
