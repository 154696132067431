import React, { useContext, useState, useEffect, useCallback } from "react";
import Context from "../../../../../../context/Context";
import { Button, Flex, Tooltip } from "antd";
import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
} from "@ant-design/icons";
import { $getSelection, $isRangeSelection, FORMAT_TEXT_COMMAND } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faHighlighter,
  faItalic,
  faUnderline,
} from "@fortawesome/free-solid-svg-icons";

export default function BasicFormatingCommands() {
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const [editor] = useLexicalComposerContext();

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsHighlight(selection.hasFormat("highlight"));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      })
    );
  }, [editor, updateToolbar]);

  const handleFormat = (
    type: "bold" | "italic" | "underline" | "highlight"
  ) => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, type);
      }
    });
  };

  return (
    <Flex gap={2}>
      <Tooltip title="Gras" mouseEnterDelay={0.5}>
        <Button
          icon={<FontAwesomeIcon icon={faBold} />}
          type={isBold ? "primary" : "default"}
          onClick={() => handleFormat("bold")}
        />
      </Tooltip>
      <Tooltip title="Italique" mouseEnterDelay={0.5}>
        <Button
          icon={<FontAwesomeIcon icon={faItalic} />}
          type={isItalic ? "primary" : "default"}
          onClick={() => handleFormat("italic")}
        />
      </Tooltip>
      <Tooltip title="Souligné" mouseEnterDelay={0.5}>
        <Button
          icon={<FontAwesomeIcon icon={faUnderline} />}
          type={isUnderline ? "primary" : "default"}
          onClick={() => handleFormat("underline")}
        />
      </Tooltip>
      <Tooltip title="Surligné" mouseEnterDelay={0.5}>
        <Button
          icon={<FontAwesomeIcon icon={faHighlighter} />}
          type={isHighlight ? "primary" : "default"}
          onClick={() => handleFormat("highlight")}
        />
      </Tooltip>
    </Flex>
  );
}
