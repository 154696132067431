import { useState, useEffect } from "react";

export default function useScreenSize() {
  const [currentScreenSize, setCurrentScreenSize] = useState<number>(0);

  useEffect(() => {
    const { innerWidth: width } = window;
    setCurrentScreenSize(width);

    function handleResize() {
      const { innerWidth: width } = window;
      setCurrentScreenSize(width);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { currentScreenSize };
}
