import { Alert, Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ThianaAssistantLogo from "../../../assets/thiana_full.png";

export default function PaymentRedirect() {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");

  useEffect(() => {
    setTimeout(() => {
      setCount(count - 1);
    }, 1000);
    if (count === 0) {
      navigate("/"); // erreur de redirection qui empêche de redemander un scope
    }
  }, [count]);

  return (
    <Flex
      vertical
      align="center"
      justify="center"
      gap={16}
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <img src={ThianaAssistantLogo} style={{ height: 150 }} />
      <Alert
        message={
          status === "success" ? "Paiement réussi." : "Erreur de paiement."
        }
        description={
          (status === "success"
            ? "Votre abonnement bien été pris en compte."
            : "Une erreur s'est produite. Le paiement n'a pas pu aboutir. Nous vous invitons à réessayer ultérieurement.") +
          " Vous allez être redirigé " +
          String(count) +
          " secondes..."
        }
        type={status === "success" ? "success" : "error"}
      />
    </Flex>
  );
}
