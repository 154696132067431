import React, { useContext, useEffect, useState } from "react";
import { Button, Flex, Input, Modal, Tag, Tooltip, Typography } from "antd";
import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import Context from "../../../../../../context/Context";
import { copyToClipboard } from "../../../../../../functions/copyToClipboard";
import useReports from "../../../../../../hooks/useReports";
import { useParams } from "react-router-dom";
import ControlCommands from "../../../../commands/ControlCommands";
import { ThianaAssistantMode } from "../../../../../../types/Reports";
import { Template } from "../../../../../../types/Templates";
import backgroundConversationImage from "../../../../../../assets/tour/conversation.png";
import { DEFAULT_TEMPLATE_UUID } from "../../../../../../utils/constants";

interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;
  templates: Template[];
  mode: ThianaAssistantMode;
}

export default function Conversation(props: Props) {
  let { reportID } = useParams();
  const [value, setValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const {
    mode,
    updateIsASRProcessing,
    isASRProcessing,
    isLLMProcessing,
    flowState,
    isSaved,
    stepsRef,
    currentReport,
    updateCurrentReport,
    updateIsSaved,
    theme,
  } = useContext(Context);
  const { autoSaveReport, autoCreateReport, updateReport } = useReports({});

  useEffect(() => {
    typeWriter(flowState.animatedLiveResponse);
    if (!updateIsASRProcessing) setValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowState.fixedLiveResponse, updateIsASRProcessing]);

  const typeWriter = (
    text: string,
    i = 0,
    wordsArray = text.split(" "),
    currentText = flowState.fixedLiveResponse
  ) => {
    if (i < wordsArray.length) {
      let newValue = currentText + wordsArray[i] + " ";
      newValue = newValue.replace(/([.!?])\s*/g, "$1\n");
      setValue(newValue);
      setTimeout(() => {
        typeWriter(text, i + 1, wordsArray, newValue);
      }, 60); // Vous pouvez ajuster le délai selon vos préférences
    }
  };

  const onChangeTranscription = (newValue: string) => {
    console.log(currentReport);
    if (currentReport && mode) {
      updateIsSaved({ isSaved: false, updatedField: "transcription" });
      updateCurrentReport({
        ...currentReport,
        transcription: newValue,
      });
      if (reportID)
        autoSaveReport({
          ...currentReport,
          transcription: newValue,
        });
      else
        autoCreateReport({
          generation: currentReport.generation,
          title: "",
          transcription: newValue,
          report_template_id: DEFAULT_TEMPLATE_UUID,
        });
    }
  };

  const HeaderConversation = () => (
    <Flex
      align="center"
      onMouseEnter={() => setIsFocus(true)}
      onMouseLeave={() => setIsFocus(false)}
      style={{
        color: isFocus || isASRProcessing ? "#6240dc" : "#bbbbbb",
        marginBottom: 4,
      }}
    >
      <div style={{ flex: 1 }}>
        {currentReport?.transcription === "" ? (
          <Tag
            bordered={false}
            style={{
              fontSize: 10,
            }}
          >
            CONVERSATION
          </Tag>
        ) : !isSaved.isSaved && isSaved.updatedField === "transcription" ? (
          <Tag bordered={false} color="orange" style={{ fontSize: 10 }}>
            MODIFICATION EN COURS <LoadingOutlined />
          </Tag>
        ) : (
          <Tag bordered={false} color="green" style={{ fontSize: 10 }}>
            TRANSCRIPTION <CheckOutlined style={{ fontSize: 8 }} />
          </Tag>
        )}
      </div>
      <div>
        <Tooltip title="Effacer la transcription" mouseEnterDelay={0.8}>
          <Button
            type={"text"}
            icon={<DeleteOutlined />}
            onClick={() => setIsModalOpen(true)}
          />
        </Tooltip>

        <Tooltip title="Copier la transcription" mouseEnterDelay={0.8}>
          <Button
            type={"text"}
            icon={<CopyOutlined />}
            onClick={() => copyToClipboard(currentReport?.transcription)}
          />
        </Tooltip>
      </div>
    </Flex>
  );

  return (
    <Flex
      vertical
      gap={12}
      style={{
        height: "100%",
      }}
      ref={stepsRef[5]}
    >
      <Flex
        vertical
        style={{
          flex: 1,
        }}
      >
        <HeaderConversation />
        {isASRProcessing ? (
          <Flex
            vertical
            style={{
              border: "1px solid #6240dc",
              borderRadius: 8,
              backgroundImage: `url(${backgroundConversationImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "calc(100vh - 238px)",
              width: "100%",
              overflowY: "scroll",
              boxSizing: "border-box",
            }}
          >
            <Flex
              flex={1}
              style={{
                boxSizing: "border-box",
                borderRadius: 8,
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 10,
                paddingRight: 10,
                letterSpacing: 0.25,
                wordSpacing: 1.1,
                width: "100%",
                backgroundColor:
                  theme.label === "light"
                    ? "rgba(255,255,255,0.9)"
                    : "rgba(0,0,0,0.9)",
              }}
            >
              <p
                style={{
                  margin: 0,
                  lineHeight: 1.5714,
                  fontSize: 14,
                  padding: 0,
                }} //line-height similaire au Input.TextArea de ant design
              >
                <Typography>{currentReport?.transcription}</Typography>
                <Typography>
                  {value.slice(
                    0,
                    flowState.liveResponseValidationIndices.validatedIndex
                  )}
                </Typography>
                <Typography style={{ color: "violet" }}>
                  {value.slice(
                    flowState.liveResponseValidationIndices.validatedIndex,
                    flowState.liveResponseValidationIndices.validatedIndex +
                      flowState.liveResponseValidationIndices.inValidationIndex
                  )}
                </Typography>
                <Typography style={{ color: "violet" }}>
                  {value.slice(
                    flowState.liveResponseValidationIndices.inValidationIndex +
                      flowState.liveResponseValidationIndices.validatedIndex
                  )}
                </Typography>
              </p>
            </Flex>
          </Flex>
        ) : (
          <Flex
            vertical
            style={{
              flex: 1,
              backgroundImage: `url(${backgroundConversationImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Input.TextArea
              onFocus={() => (isFocus ? setIsFocus(true) : null)}
              onBlur={() => setIsFocus(false)}
              style={{
                flex: 1,
                textAlign: "justify",
                borderRadius: 8,
                backgroundColor:
                  theme.label === "light"
                    ? "rgba(255,255,255,0.9)"
                    : "rgba(0,0,0,0.9)",
              }}
              value={currentReport?.transcription}
              onChange={(e) => onChangeTranscription(e.target.value)}
              disabled={isLLMProcessing || isASRProcessing}
              onMouseEnter={() => setIsFocus(true)}
              onMouseLeave={() => setIsFocus(false)}
              data-cy="action-input-transcription"
            ></Input.TextArea>
          </Flex>
        )}
      </Flex>

      <Modal
        title={
          <>
            <ExclamationCircleFilled
              style={{
                height: 32,
                width: 32,
                fontSize: 22,
                color: "#ff4d4f",
              }}
            />
            Etes-vous sûr de vouloir effacer la transcription ?
          </>
        }
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button
            key="back"
            type="default"
            onClick={() => setIsModalOpen(false)}
          >
            Annuler
          </Button>,
          <Button
            onClick={() => {
              if (currentReport) {
                updateCurrentReport({
                  ...currentReport,
                  transcription: "",
                });
                updateReport({
                  ...currentReport,
                  transcription: "",
                });
                setIsModalOpen(false);
              }
            }}
            type="primary"
            danger
            key="submit"
          >
            Supprimer
          </Button>,
        ]}
      >
        <p>
          Une fois supprimées, les données seront définitivement perdues.
          Etes-vous sur de vouloir continuer ?
        </p>
        <p>Si non, cliquez sur le bouton annuler</p>
      </Modal>

      <Flex gap={4}>
        <ControlCommands
          SOCKET_ASR={props.SOCKET_ASR}
          mode={props.mode}
          templates={props.templates}
        />
      </Flex>
    </Flex>
  );
}
