import { RequestContext } from "@thiana/api-thiana-client";

export const preMiddleware = async (context: RequestContext) => {
  context.init.headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessJWT")}`,
    "X-Refresh-Token": `${localStorage.getItem("refreshJWT")}`,
  };
  return context;
};
