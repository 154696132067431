import React from "react";
import { faListOl, faListUl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Button, Flex } from "antd";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from "@lexical/list";
import { COMMAND_PRIORITY_LOW } from "lexical";
import { insertList } from "@lexical/list";

export default function ListCommands() {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    editor.registerCommand(
      INSERT_UNORDERED_LIST_COMMAND,
      () => {
        insertList(editor, "bullet");
        return true;
      },
      COMMAND_PRIORITY_LOW
    );

    editor.registerCommand(
      INSERT_ORDERED_LIST_COMMAND,
      () => {
        insertList(editor, "number");
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor]);

  const handleUnorderedList = () => {
    console.log("ok");
    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
  };

  const handleOrderedList = () => {
    editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
  };

  return (
    <Flex gap={2}>
      <Button
        icon={<FontAwesomeIcon icon={faListUl} />}
        onClick={() => handleUnorderedList()}
      />
      <Button
        icon={<FontAwesomeIcon icon={faListOl} />}
        onClick={() => handleOrderedList()}
      />
    </Flex>
  );
}
