import React, { useEffect, useState } from "react";
import { InputNumber, Tooltip } from "antd";
import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  $createTextNode,
  $isElementNode,
} from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

export default function FontSizeSelect() {
  const [editor] = useLexicalComposerContext();
  const [fontSize, setFontSize] = useState<number | null>(null);

  useEffect(() => {
    const updateFontSize = () => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
        }
      });
    };

    // Register the update listener
    const unregisterListener = editor.registerUpdateListener(updateFontSize);
    return () => unregisterListener();
  }, [editor]);

  const handleFontSizeChange = (value: number | null) => {
    setFontSize(value);
    if (value !== null) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const nodes = selection.extract();
          nodes.forEach((node) => {
            if ($isTextNode(node)) {
              const style = node.getStyle();
              const hasFontSize = /font-size/.test(style);
              if (hasFontSize) {
                const newStyle = style.replace(
                  /font-size: \d+px;/,
                  `font-size: ${value}px;`
                );
                node.setStyle(newStyle);
              } else node.setStyle(style + ` font-size: ${value}px;`);
            } else if ($isElementNode(node)) {
              node.getChildren().forEach((childNode) => {
                if ($isTextNode(childNode) && childNode.getParent() === null) {
                  const style = childNode.getStyle();
                  const hasFontSize = /font-size/.test(style);
                  if (hasFontSize) {
                    const newStyle = style.replace(
                      /font-size: \d+px;/,
                      `font-size: ${value}px;`
                    );
                    childNode.setStyle(newStyle);
                  } else {
                    childNode.setStyle(style + ` font-size: ${value}px;`);
                  }
                }
              });
            } else {
              const textContent = node.getTextContent();
              const textNode = $createTextNode(textContent);
              textNode.setStyle(` font-size: ${value}px;`);
              node.replace(textNode);
            }
          });
        }
      });
    }
  };

  return (
    <Tooltip title="Taille de police" mouseEnterDelay={0.5}>
      <InputNumber
        value={fontSize}
        min={8}
        max={72}
        style={{ width: 50 }}
        onChange={(value) => handleFontSizeChange(value)}
        placeholder="Taille de police"
      />
    </Tooltip>
  );
}
