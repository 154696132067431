import React, { useCallback, useEffect } from "react";
import { Button, Flex, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
} from "@fortawesome/free-solid-svg-icons";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  LexicalNode,
} from "lexical";
import { mergeRegister } from "@lexical/utils";

export default function TextAlignmentCommands() {
  const [editor] = useLexicalComposerContext();

  const handleAlign = (alignment: "left" | "center" | "right" | "justify") => {
    editor.update(() => {
      editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, alignment);
    });
  };

  return (
    <Flex gap={2}>
      <Tooltip title="Aligner à gauche" mouseEnterDelay={0.5}>
        <Button
          icon={<FontAwesomeIcon icon={faAlignLeft} />}
          onClick={() => handleAlign("left")}
        />
      </Tooltip>
      <Tooltip title="Centrer" mouseEnterDelay={0.5}>
        <Button
          icon={<FontAwesomeIcon icon={faAlignCenter} />}
          onClick={() => handleAlign("center")}
        />
      </Tooltip>
      <Tooltip title="Aligner à droite" mouseEnterDelay={0.5}>
        <Button
          icon={<FontAwesomeIcon icon={faAlignRight} />}
          onClick={() => handleAlign("right")}
        />
      </Tooltip>
      <Tooltip title="Justifier" mouseEnterDelay={0.5}>
        <Button
          icon={<FontAwesomeIcon icon={faAlignJustify} />}
          onClick={() => handleAlign("justify")}
        />
      </Tooltip>
    </Flex>
  );
}
