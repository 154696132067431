export type Template = {
  id: string;
  title: string;
  content: string;
  generation: string;
  professional_id: string;
  parent_id: string; // template_id first version
  created_at: string;
  updated_at: string;
  deleted_at: string;
  version: number;
};

export type TemplatesResponse = {
  data: Template[] | null;
  metadata?: Metadata;
};

export type Metadata = {
  pages: number;
  total: number;
};

export const emptyTemplate: Template = {
  id: "",
  title: "",
  content: "",
  generation: "",
  professional_id: "",
  parent_id: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  version: 0,
};
