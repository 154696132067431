import { useContext, useEffect, useState } from "react";
import DesktopLayout from "./desktop/DesktopLayout";
import MobileLayout from "./mobile/MobileLayout";
import breakpoints from "../../../theme/antdesign";
import HeaderDocument from "./HeaderDocument";
import useScreenSize from "../../../hooks/useScreensize";
import Context from "../../../context/Context";
import { useParams } from "react-router-dom";
import { ThianaAssistantMode, emptyReport } from "../../../types/Reports";
import { Report, ReportOutput } from "@thiana/api-thiana-client";
import { Flex, Tour, TourStepProps, Typography, message } from "antd";
import { Template, TemplatesResponse } from "../../../types/Templates";
import useProfessionals from "../../../hooks/useProfessionals";
import { apiReports } from "../../../api-configuration/Configuration";
import { getTemplateById } from "../../../api/api";
import { DEFAULT_TEMPLATE_UUID } from "../../../utils/constants";
import useReports from "../../../hooks/useReports";
interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
  mode: ThianaAssistantMode;
  steps: TourStepProps[];
}

export default function Document(props: Props) {
  const {
    isMenuHidden,
    dispatchFlow,
    updateIsMenuHidden,
    updateIsMicrophoneAvailable,
    openTour,
    professional,
    currentReport,
    updateCurrentReport,
    updateOpenTour,
  } = useContext(Context);
  const { reportID } = useParams();
  const { currentScreenSize } = useScreenSize();
  const [messageApi, contextHolder] = message.useMessage();
  const [isVisible, setIsVisible] = useState(false);
  const { patchProfessional } = useProfessionals();
  useEffect(() => {
    setIsVisible(true);
  }, []);

  // On récupère le report quand l'id change dans l'URL

  const { fetchReportByID } = useReports({});

  useEffect(() => {
    // Vérifier si la permission d'accéder au microphone a été accordée
    (async () => {
      await navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(
          (stream) => {
            updateIsMicrophoneAvailable(true);
          },
          (e) => {
            updateIsMicrophoneAvailable(true);
            messageApi.open({
              type: "warning",
              content:
                "Vous n'avez pas autorisé l'accès au microphone. Veuillez le faire dans les paramètres de votre navigateur.",
            });
          }
        );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (reportID) {
        dispatchFlow({ type: "SWITCH_REPORT" });
        const report = await fetchReportByID(reportID);
        updateCurrentReport(report as Report);
      } else {
        let newReport = { ...emptyReport };
        //GET default template value
        let templatesResponse: TemplatesResponse;
        let response = await getTemplateById(DEFAULT_TEMPLATE_UUID);
        if (response.status === 200) {
          templatesResponse = await response.json();
          if (templatesResponse.data !== null)
            newReport = {
              ...newReport,
              generation: templatesResponse.data[0].content,
            };
        }
        if (currentReport)
          updateCurrentReport({
            ...newReport,
          });
        else updateCurrentReport(newReport);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportID]);

  useEffect(() => {
    (async () => {
      if (
        professional &&
        professional.is_assistant_tutorial_displayed === false
      ) {
        updateOpenTour(true);
        // On met à jour le professionel
        await patchProfessional(professional.id, {
          is_assistant_tutorial_displayed: true,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professional]);

  return (
    <Flex
      vertical
      flex={1}
      gap={4}
      className={isVisible ? "slide-in active" : "slide-in"}
      style={
        {
          // height: `calc(100vh - 32px)`,
          // border: "1px solid blue",
          // zIndex: 9999,
        }
      }
      onClick={() =>
        currentScreenSize < breakpoints.sm && !isMenuHidden
          ? updateIsMenuHidden(true)
          : null
      }
    >
      <Tour
        open={openTour}
        onClose={() => updateOpenTour(false)}
        steps={props.steps}
        disabledInteraction={true}
      />
      {contextHolder}
      <Flex style={{ boxSizing: "border-box" }}>
        <HeaderDocument SOCKET_ASR={props.SOCKET_ASR} />
      </Flex>

      <Flex flex={1}>
        {currentScreenSize < breakpoints.sm ? (
          <MobileLayout
            SOCKET_ASR={props.SOCKET_ASR}
            templates={props.templates}
            setTemplates={props.setTemplates}
            mode={props.mode}
          />
        ) : (
          <DesktopLayout
            SOCKET_ASR={props.SOCKET_ASR}
            templates={props.templates}
            setTemplates={props.setTemplates}
            mode={props.mode}
          />
        )}
      </Flex>

      <Flex align="center" justify="center">
        <p
          style={{
            fontSize: 10,
            opacity: 0.3,
            textAlign: "center",
          }}
        >
          Thiana utilise un modèle d'IA générative. Par conséquent, les
          observations médicales générées peuvent comporter des erreurs ou être
          incomplètes. Veillez à toujours vérifier le contenu de l'observation
          avant de vous en servir.
        </p>
      </Flex>
    </Flex>
  );
}
