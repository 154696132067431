import { ColorPicker, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  $isTextNode,
  $createTextNode,
  $isElementNode,
} from "lexical";
import type { Color } from "antd/es/color-picker";
import { PresetsItem } from "antd/es/color-picker/interface";
import { mergeRegister } from "@lexical/utils";

export default function ColorSelect() {
  const [editor] = useLexicalComposerContext();
  const [color, setColor] = useState<string>("#000000");

  const handleColorChange = (pickedColor: Color) => {
    const rgba = pickedColor.toRgbString();

    console.log("font", rgba); // Here the font logged is "Trebuchet MS"
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const nodes = selection.extract();
        nodes.forEach((node) => {
          if ($isTextNode(node)) {
            const style = node.getStyle();
            console.log("style", style);
            const hasFontColor = /color/.test(style);
            if (hasFontColor) {
              console.log("yes");
              const newStyle = style.replace(
                /color:\s*[^;]+;/,
                `color: ${rgba};`
              );
              node.setStyle(newStyle);
            } else node.setStyle(style + ` color: ${rgba};`);
          } else if ($isElementNode(node)) {
            node.getChildren().forEach((childNode) => {
              if ($isTextNode(childNode) && childNode.getParent() === null) {
                const style = childNode.getStyle();
                const hasFontColor = /color/.test(style);
                if (hasFontColor) {
                  const newStyle = style.replace(
                    /color:\s*[^;]+;/,
                    `color: ${rgba};`
                  );
                  childNode.setStyle(newStyle);
                } else {
                  childNode.setStyle(style + ` color: ${rgba};`);
                }
              }
            });
          } else {
            const textContent = node.getTextContent();
            const textNode = $createTextNode(textContent);
            textNode.setStyle(` color: ${rgba};`);
            node.replace(textNode);
          }
        });
      }
    });
  };

  const colorPresets: PresetsItem[] = [
    {
      label: "Couleurs",
      colors: [
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#F7E600",
        "#900C3F",
        "#581845",
      ],
    },
  ];

  return (
    <Tooltip title="Couleur du texte" mouseEnterDelay={0.5}>
      <ColorPicker
        defaultValue="#000000"
        onChangeComplete={(color) => handleColorChange(color)}
        presets={colorPresets}
      />
    </Tooltip>
  );
}
