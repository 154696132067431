import { Avatar, Button, Flex, Popover, Typography } from "antd";
import { useContext } from "react";
import Context from "../../../context/Context";
import { useNavigate } from "react-router-dom";

interface Props {
  fileInputRef: React.RefObject<HTMLInputElement>;
}

export default function Profile(props: Props) {
  const { user, professional, fileAvatar, updateOpenTour, theme, updateTheme } =
    useContext(Context);
  const navigate = useNavigate();
  const content = (
    <Flex
      gap={"small"}
      vertical
      style={{
        width: 288,
        maxWidth: 288,
      }}
    >
      <Button
        style={{ width: "100%" }}
        onClick={() => {
          if (theme.label === "dark") {
            localStorage.setItem("theme", "light");
            updateTheme({ ...theme, label: "light" });
          } else {
            localStorage.setItem("theme", "dark");
            updateTheme({ ...theme, label: "dark" });
          }
        }}
      >
        {theme.label === "light" ? "Theme sombre" : "Thème clair"}
      </Button>
      <Button
        style={{ width: "100%" }}
        onClick={() => {
          navigate("/documents");
          updateOpenTour(true);
        }}
      >
        Rejouer le tutoriel
      </Button>
      <Button
        style={{ width: "100%" }}
        onClick={() => {
          props.fileInputRef.current?.click();
        }}
      >
        Changer ma photo
      </Button>
      <Button
        onClick={() => {
          navigate("/logout");
        }}
        style={{ width: "100%" }}
      >
        Déconnexion
      </Button>
    </Flex>
  );

  return (
    <Popover content={content} trigger="click" placement="top">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 8,
          cursor: "pointer",
        }}
      >
        <Avatar style={{ width: 40, height: 40 }} src={fileAvatar} />
        <Flex vertical>
          <Typography style={{ margin: 0, lineHeight: 1.2 }}>
            {user?.first_name} {user?.last_name}
          </Typography>
          {/* <Typography style={{ color: "grey", fontSize: 10, lineHeight: 1.2 }}>
            {professional?.status && professional?.status.length > 20
              ? professional?.status.slice(0, 20).toUpperCase() + "..."
              : professional?.status.toUpperCase()}
          </Typography> */}
        </Flex>
      </div>
    </Popover>
  );
}
