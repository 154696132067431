const theme = {
  paragraph: "p",
  text: {
    bold: "bold",
    italic: "italic",
    underline: "underline",
    hightlight: "highlight",
  },
  align: {
    left: "al-l",
    center: "al-c",
    right: "al-r",
    justify: "al-j",
  },
  list: {
    ul: "ul",
    ol: "ol",
    listitem: "il",
  },
};

export default theme;
