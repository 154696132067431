import { useContext, useState } from "react";
import { getReports, postReport, putReport } from "../api/api";
import Context from "../context/Context";
import { useNavigate } from "react-router-dom";
import {
  apiReportSnapshots,
  apiReports,
} from "../api-configuration/Configuration";
import {
  Report as ThianaReport,
  ReportInput,
  ReportOutput,
} from "@thiana/api-thiana-client";
import { notification } from "antd";
import { ThianaPagination } from "../types/Pagination";
import { emptyReport } from "../types/Reports";

interface Props {
  paginationReports?: ThianaPagination;
  setPaginationReports?: React.Dispatch<React.SetStateAction<ThianaPagination>>;
}

export default function useReports(props: Props) {
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const { reports, updateReports } = useContext(Context);

  const {
    updateCurrentReport,
    updateIsSaved,
    dispatchFlow,
    updateCurrentTranscription,
    updateCurrentContent,
    updateIsASRProcessing,
    updateIsLLMProcessing,
  } = useContext(Context);

  /**
   * Sauvegarde du report en bdd
   * @param report
   * @returns 200 if success
   */
  const updateReport = async (report: ThianaReport): Promise<number> => {
    let response = await putReport(report.id, report);
    let responseStatus = response.status;
    if (response.status === 200) {
      updateIsSaved({ isSaved: true });
      let reportsResponse: any = await response.json(); //TODO - Remettre le type et la requete avec le api-thiana-client
      updateCurrentReport(reportsResponse.data[0]);
      // On update la liste des reports
      let tmpReports = [...reports];
      let foundedIndex = tmpReports.findIndex(
        (reportFI: ThianaReport) => reportFI.id === report.id
      );
      tmpReports[foundedIndex] = reportsResponse.data[0];
      updateReports(tmpReports);
    }
    return responseStatus;
  };

  const autoSaveReport = async (updatedReport: ThianaReport) => {
    // Réinitialiser le délai si l'utilisateur continue de taper
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      updateReport(updatedReport);
      // updateCurrentContent(updatedReport.transcription);
    }, 1000);

    // Mettre à jour l'ID du timer
    setTimerId(newTimerId);
  };

  const autoCreateReport = async (data?: ReportInput) => {
    // Réinitialiser le délai si l'utilisateur continue de taper
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      createReport(data);
    }, 1000);

    // Mettre à jour l'ID du timer
    setTimerId(newTimerId);
  };

  let navigate = useNavigate();

  const createReport = async (
    data?: ReportInput
  ): Promise<ThianaReport | undefined> => {
    let reportsResponse: any; //TODO - Remettre le type et la requete avec le api-thiana-client
    let response = await postReport(data);
    if (response.status === 201) {
      updateIsSaved({ isSaved: true });
      reportsResponse = await response.json();
      // On met à jour le report courant
      updateCurrentReport(reportsResponse.data[0]);
      // On navigue vers le nouveau report
      navigate("/documents/" + reportsResponse.data[0].id);
      return reportsResponse.data[0];
    }
  };

  const createSnapshot = async (report: ThianaReport) => {
    await apiReportSnapshots.reportSnapshotCreateRaw({
      id: report.id,
      reportSnapshotInput: {
        audio_path: "",
        transcription: report.transcription,
        generation: report.generation,
      },
    });
  };

  const deleteManyReports = async (ids: string[]) => {
    let count = 0;
    let promises = ids.map(async (id: string) => {
      const response = await apiReports.reportDeleteRaw({ id });

      if (response.raw.status === 204) {
        count++;
        return "success";
      } else return "failed";
    });
    let result: string[] = await Promise.all(promises);
    let success = result.filter((element) => element !== "failed");
    if (success.length !== ids.length)
      notification.warning({
        message: "Attention",
        description:
          ids.length - count + " documents n'ont pas pu être supprimés.",
        duration: 3,
      });
    else
      notification.success({
        message: "Suppression réussie.",
        description: "Les documents ont bien été supprimés.",
        duration: 3,
      });
    fetchReports();
  };

  // Fetch reports with pagination
  const fetchReports = async () => {
    if (props.paginationReports && props.setPaginationReports) {
      let reportsResponse: any; ///TODO - Remettre le type
      const response = await getReports(
        props.paginationReports.items,
        props.paginationReports.currentPage
      );
      if (response.status === 200) {
        reportsResponse = await response.json();
        if (reportsResponse?.data !== null) {
          if (props.paginationReports.currentPage === 1) {
            updateReports(reportsResponse.data);
            if (reportsResponse.metadata) {
              props.setPaginationReports({
                ...props.paginationReports,
                totalItems: reportsResponse.metadata?.total,
                nbPages: reportsResponse.metadata?.pages,
              });
            }
          } else updateReports([...reports].concat(reportsResponse.data));
        } else updateReports([]);
      }
    }
  };

  const fetchReportByID = async (id: string) => {
    let report: ReportOutput;
    const response = await apiReports.reportGetRaw({
      id,
    });
    if (response.raw.status === 200) {
      report = await response.raw.json();
      // console.log("report.data[0]", report.data[0]);
      // updateCurrentReport(report.data[0]);
      return report.data[0] as ThianaReport;
    }
  };

  const resetAppState = () => {
    dispatchFlow({ type: "RESET_APP_STATE" });
    updateCurrentTranscription("");
    updateCurrentContent("");
    updateCurrentReport(emptyReport);
    updateIsASRProcessing(false);
    updateIsLLMProcessing(false);
    navigate("/documents");
  };

  return {
    autoSaveReport,
    autoCreateReport,
    createReport,
    updateReport,
    resetAppState,
    createSnapshot,
    deleteManyReports,
    fetchReports,
    fetchReportByID,
  };
}
