import jwt_decode from "jwt-decode";
import { ACL, AccessToken } from "../types/Token";
const hasValidToken = () => {
  let encodedToken: any = localStorage.getItem("accessJWT");
  if (encodedToken === null) return false;
  let token: AccessToken = jwt_decode(encodedToken);
  if (Date.now() >= token.exp * 1000) {
    window.location.replace("/logout");
    return false;
  }
  return true;
};
const isIAAllowed = (status?: string) => {
  switch (status) {
    case "canceled":
      return false;
    case "trialing":
      return true;
    case "active":
      return true;
    default:
      return false;
  }
};

const getACL = (): ACL => {
  // return 10; // Simulate LevelAssistantIntegral = 10
  // return 11; // Simulate LevelAssistantRead = 11
  // return 12; // Simulate LevelAssistantEssential = 12
  let encodedToken: any = localStorage.getItem("accessJWT");
  if (encodedToken === null) return 0;
  let token: AccessToken = jwt_decode(encodedToken);
  return token.acl;
};

const getFormattedACL = (): string => {
  switch (getACL()) {
    case 0:
      return "Aucun accès";
    case 1:
      return "Accès restreint";
    case 10:
      return "INTÉGRAL";
    case 11:
      return "Aucun abonnement";
    case 12:
      return "ESSENTIEL";

    default:
      return "Erreur";
  }
};

export { hasValidToken, isIAAllowed, getACL, getFormattedACL };
