import { Button, Flex, Input, Modal, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  ReportTemplateShortcut,
  ReportTemplateShortcutOutput,
} from "@thiana/api-thiana-client";
import Context from "../../../../context/Context";
import { apiTemplateReports } from "../../../../api-configuration/Configuration";
import { useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  DeleteFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  LeftOutlined,
  PlusOutlined,
} from "@ant-design/icons";

interface Props {}

export default function ShortcutsDrawer(props: Props) {
  const [view, setView] = useState<"list" | "add" | "edit">("list");
  const { theme } = useContext(Context);
  const { templateID } = useParams();
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [currentShortcutID, setCurrentShortcutID] = useState<string>("");
  const [shortcuts, setShortcuts] = useState<ReportTemplateShortcut[]>([]);
  const [showShortcutDeleteModel, setShowShortcutDeleteModal] =
    useState<boolean>(false);

  // Fonction pour enregistrer un raccourci en base de données
  const saveShortcut = async (shorcutID: string | undefined = undefined) => {
    if (templateID) {
      console.log(templateID);
      // Appel à l'API pour ajouter un raccourci
      if (!shorcutID) {
        const response =
          await apiTemplateReports.templateReportShortcutCreateRaw({
            templateId: templateID,
            reportTemplateShortcutInput: {
              content: content,
              title: title,
            },
          });
        if (response.raw.status === 201) {
          let output: ReportTemplateShortcutOutput = await response.raw.json();
          if (shortcuts) setShortcuts([...shortcuts, output.data[0]]);
          else setShortcuts([output.data[0]]);
          setTitle("");
          setContent("");
          setView("list");
        }
      } else {
        const response = await apiTemplateReports.templateReportShortcutUpdate({
          templateId: templateID,
          id: currentShortcutID,
          reportTemplateShortcutInput: {
            content: content,
            title: title,
          },
        });
        setContent("");
        setTitle("");
        setCurrentShortcutID("");
        setView("list");
      }
    }
  };

  useEffect(() => {
    getShortcuts();
    // console.log(templateID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateID, currentShortcutID]);

  // Fonction pour récupérer les raccourcis en base de données
  const getShortcuts = async () => {
    if (templateID) {
      console.log("templateID", templateID);
      // Appel à l'API pour récupérer les raccourcis
      const response = await apiTemplateReports.templateReportShortcutGetAllRaw(
        {
          templateId: templateID,
          equal: "report_template_id:" + templateID,
        }
      );
      if (response.raw.status === 200) {
        let output: ReportTemplateShortcutOutput = await response.raw.json();
        console.log("output.data", output.data);
        if (output.data) setShortcuts(output.data);
        else setShortcuts([]);
      }
    }
  };

  // Fonction pour supprimer un raccourci en base de données
  const deleteShortcut = async (shortcutID: string) => {
    if (templateID) {
      const response = await apiTemplateReports.templateReportShortcutDeleteRaw(
        {
          templateId: templateID,
          id: shortcutID,
        }
      );
      if (response.raw.status === 204) {
        getShortcuts();
      }
    }
  };

  // Fonction pour éditer un raccourcis
  const editShortcut = async (index: number) => {
    console.log(index);
    console.log(shortcuts[index]);
    // const response = await apiTemplateReports.templateReportGet({
    //   id: shortcutID,
    // });
    // console.log("response", response);
    setTitle(shortcuts[index].title);
    setContent(shortcuts[index].content);
    setCurrentShortcutID(shortcuts[index].id);
    setView("edit");
  };

  return (
    <div>
      {view === "list" ? (
        <Flex vertical>
          <div style={{ alignSelf: "end" }}>
            <Button
              onClick={() => {
                setTitle("");
                setContent("");
                setView("add");
              }}
              style={{ marginBottom: 12 }}
              icon={<PlusOutlined />}
            >
              Ajouter un raccourci
            </Button>
          </div>
          {shortcuts.length > 0 ? (
            <Flex vertical gap={"small"}>
              {shortcuts?.map(
                (shortcut: ReportTemplateShortcut, index: number) => (
                  <Flex
                    style={{
                      border: "1px solid " + theme.sideBackgroundColor,
                      borderRadius: "8px",
                      padding: "8px",
                      alignItems: "center",
                    }}
                  >
                    <Flex vertical style={{ flex: 1 }} key={index}>
                      <Typography style={{ fontWeight: "bold" }}>
                        {shortcut.title}
                      </Typography>
                      <Typography>{shortcut.content}</Typography>
                    </Flex>
                    <Button
                      onClick={() => editShortcut(index)}
                      icon={<EditOutlined />}
                      type="text"
                    ></Button>
                    <Button
                      onClick={() => setShowShortcutDeleteModal(true)}
                      icon={<DeleteOutlined />}
                      type="text"
                    ></Button>
                    <Modal
                      open={showShortcutDeleteModel}
                      footer={(_, { OkBtn, CancelBtn }) => (
                        <>
                          <Button
                            onClick={() => setShowShortcutDeleteModal(false)}
                          >
                            Annuler
                          </Button>
                          <Button
                            type="primary"
                            danger
                            onClick={() => deleteShortcut(shortcut.id)}
                          >
                            Supprimer
                          </Button>
                        </>
                      )}
                      title={
                        <>
                          <ExclamationCircleFilled
                            style={{
                              height: 32,
                              width: 32,
                              fontSize: 22,
                              color: "#ff4d4f",
                            }}
                          />
                          Suppression d'un raccourcis
                        </>
                      }
                      onCancel={() => setShowShortcutDeleteModal(false)}
                    >
                      <p>
                        Etes-vous sûr(e) de vouloir supprimer ce raccourcis ?
                        Une fois supprimé, vous ne pourrez plus le récupérer.
                      </p>
                    </Modal>
                  </Flex>
                )
              )}
            </Flex>
          ) : (
            <Typography style={{ opacity: 0.3, textAlign: "center" }}>
              Aucun raccourci pour ce modèle
            </Typography>
          )}
        </Flex>
      ) : (
        <Flex vertical gap={"small"}>
          <div>
            <Button
              style={{ flex: 1, marginBottom: 12 }}
              onClick={() => setView("list")}
              icon={<LeftOutlined />}
              type={"text"}
            >
              Retour
            </Button>
          </div>
          <Input
            style={{ paddingLeft: 4 }}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            placeholder="Saisir le titre du raccourci..."
            prefix={
              <div
                style={{
                  fontSize: 12,
                  backgroundColor: theme.contentBackgroundColor,
                  borderRadius: 10,
                  padding: "0px 8px",
                }}
              >
                Titre
              </div>
            }
          ></Input>
          <Input.TextArea
            onChange={(e) => setContent(e.target.value)}
            value={content}
            rows={10}
            placeholder="Saisir le texte du raccourci..."
          ></Input.TextArea>

          <Tooltip
            title="Vous devez renseigner un titre et un contenu avant d'enregistrer"
            mouseEnterDelay={title === "" || content === "" ? 0.1 : 9999999}
          >
            <Button
              style={{ flex: 1 }}
              onClick={() =>
                saveShortcut(view === "add" ? undefined : currentShortcutID)
              }
              type="primary"
              disabled={title === "" || content === ""}
            >
              Enregistrer
            </Button>
          </Tooltip>
        </Flex>
      )}
    </div>
  );
}
