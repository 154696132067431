import { Report as ThianaReport } from "@thiana/api-thiana-client";
import { DEFAULT_TEMPLATE_UUID } from "../utils/constants";
export type ClassifiedReports = { [key: string]: ThianaReport[] };
export const emptyReport: ThianaReport = {
  id: "",
  transcription: "",
  processings: [],
  title: "",
  generation:
    "<p><b><strong>Motif :</strong></b><span> </span></p><ul><li></li></ul><p><b><strong>Traitements habituels :</strong></b><span> </span></p><ul><li></li></ul><p><b><strong>Antécédents médicaux :</strong></b><span> </span></p><ul><li></li></ul><p><b><strong>Antécédents chirurgicaux :</strong></b><span> </span></p><ul><li></li></ul><p><b><strong>Allergies :</strong></b><span> </span></p><ul><li></li></ul><p><b><strong>Mode de vie :</strong></b><span> </span></p><ul><li></li></ul><p><b><strong>Anamnèse :</strong></b><span> </span></p><p><span>[anamnèse détaillée]</span></p><p><b><strong>Examen physique :</strong></b><span> </span></p><ul><li></li></ul><p><b><strong>Constantes :</strong></b><span> </span></p><ul><li></li></ul><p><b><strong>Résultats biologiques :</strong></b><span> </span></p><ul><li></li></ul><p><b><strong>Résultats d'imagerie :</strong></b><span> </span></p><ul><li></li></ul><p><b><strong>Prise en charge :</strong></b><span> </span></p><ul><li></li></ul>",
  professional_id: "",
  report_template_id: DEFAULT_TEMPLATE_UUID,
  created_at: "",
  updated_at: "",
  feedbacks: [],
};

export type ThianaAssistantMode = "statement" | "conversation";
