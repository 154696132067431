import { Flex, Switch, Typography, Tag, Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Context from "../../../../context/Context";
import PlanInfos from "./PlanInfos";

export default function PaymentModalContent() {
  const [isMonthly, setIsMonthly] = useState(false);
  const { theme } = useContext(Context);

  const baseStyle = {
    borderRadius: 16,
    padding: "16px",
    boxShadow:
      "0px 32px 41px -23px rgba(24, 24, 28, 0.07), 0px 2px 6px 0px rgba(24, 24, 28, 0.06)",
  };

  const titleStyle = { fontSize: "20px", fontWeight: 700 };

  const essentialItems = [{ type: "title", content: "Fonctionnalités" }, {}];

  return (
    <Flex vertical style={{ padding: 16 }} gap={8}>
      <Flex flex={1} gap={4} justify="center" align="center">
        <Typography>Annuel</Typography>
        <Switch
          value={isMonthly}
          onChange={(checked) => setIsMonthly(checked)}
        />
        <Typography>Mensuel</Typography>
      </Flex>
      <Flex gap={8}>
        <PlanInfos
          type="essentielle"
          isMonthly={isMonthly}
          style={{
            ...baseStyle,
            border: "1px solid " + theme.sideBackgroundColor,
          }}
          priceID={
            isMonthly
              ? (process.env
                  .REACT_APP_STRIPE_PRICE_ID_ESSENTIAL_MONTHLY as string)
              : (process.env
                  .REACT_APP_STRIPE_PRICE_ID_ESSENTIAL_YEARLY as string)
          }
        />
        <PlanInfos
          type="intégrale"
          isMonthly={isMonthly}
          style={{ ...baseStyle, color: "white", backgroundColor: "#0958D9" }}
          priceID={
            isMonthly
              ? (process.env
                  .REACT_APP_STRIPE_PRICE_ID_INTEGRAL_MONTHLY as string)
              : (process.env
                  .REACT_APP_STRIPE_PRICE_ID_INTEGRAL_YEARLY as string)
          }
        />
        <PlanInfos
          type="entreprise"
          isMonthly={isMonthly}
          style={{ ...baseStyle, color: "white", backgroundColor: "#6B2ADF" }}
          priceID={""}
        />
      </Flex>
    </Flex>
  );
}
