import { useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postAskNewTokenByScope } from "../../../api/api";
import Context from "../../../context/Context";
import { apiAuth } from "../../../api-configuration/Configuration";
import { MeOutput } from "@thiana/api-thiana-client";
import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Typography } from "antd";

export default function AuthController() {
  let navigate = useNavigate();
  let { accessTokenFromURL } = useParams();
  const { updateUser, updateProfessional, updateSubscription } =
    useContext(Context);

  useEffect(() => {
    setTimeout(() => {
      if (accessTokenFromURL) tryToConnectWithTokensFromURL();
      else tryToConnectWithTokensFromStorage();
    }, 1000);
  });

  const tryToConnectWithTokensFromURL = async () => {
    if (accessTokenFromURL) {
      let response = await postAskNewTokenByScope(
        { scope: 1 },
        accessTokenFromURL
      );
      if (response.status === 200) {
        navigate("/documents");
      }
    }
  };

  const tryToConnectWithTokensFromStorage = async () => {
    const accessToken = localStorage.getItem("accessJWT");
    const refreshToken = localStorage.getItem("refreshJWT");
    if (accessToken && refreshToken) {
      let response = await apiAuth.authUserMeRaw();
      if (response.raw.status === 200) {
        navigate("/documents");
        let me: MeOutput = await response.raw.json();
        updateUser(me.data[0].user);
        updateProfessional(me.data[0].professional);
        updateSubscription(me.data[0].subscription);
      }
    } else navigate("/logout");
  };

  const { theme } = useContext(Context);

  return (
    <Flex
      vertical
      gap={"middle"}
      align="center"
      justify="center"
      style={{
        height: "100vh",
        backgroundColor: theme.sideBackgroundColor,
      }}
    >
      <LoadingOutlined style={{ fontSize: 24, color: theme.iconColor }} />
      <Typography>Connexion à Thiana Assistant</Typography>
    </Flex>
  );
}
