import { useContext } from "react";
import Context from "../context/Context";
import {
  ProfessionalOutput,
  ProfessionalPartialInput,
} from "@thiana/api-thiana-client";
import { apiProfessionals } from "../api-configuration/Configuration";

export default function useProfessionals() {
  const { updateProfessional } = useContext(Context);

  const patchProfessional = async (
    id: string,
    data: ProfessionalPartialInput
  ) => {
    let response = await apiProfessionals.professionalUpdateRaw({
      id: id,
      professionalPartialInput: data,
    });
    if (response.raw.status === 200) {
      let professionalOutput: ProfessionalOutput = await response.raw.json();
      updateProfessional(professionalOutput.data[0]);
    }
  };

  return {
    patchProfessional,
  };
}
