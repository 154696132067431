import Generation from "./right-side/Generation";
import { Template } from "../../../../types/Templates";
import { ThianaAssistantMode } from "../../../../types/Reports";
import LeftSideWrapper from "./left-side/LeftSideWrapper";
import { Flex } from "antd";

interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;

  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
  mode: ThianaAssistantMode;
}
export default function DesktopLayout(props: Props) {
  return (
    <Flex
      gap={8}
      flex={1}
      style={
        {
          // height: "100%",
          // maxHeight: "100%",
        }
      }
    >
      <LeftSideWrapper
        SOCKET_ASR={props.SOCKET_ASR}
        mode={props.mode}
        templates={props.templates}
      />

      <Generation
        templates={props.templates}
        setTemplates={props.setTemplates}
      />
    </Flex>
  );
}
