import {
  AudioOutlined,
  CommentOutlined,
  DiffOutlined,
} from "@ant-design/icons";
import { Flex, Segmented } from "antd";
import React, { useContext, useState } from "react";
import { ThianaAssistantMode } from "../../../../../types/Reports";
import { Template } from "../../../../../types/Templates";
import Shortcuts from "./tabs/Shortcuts";
import Conversation from "./tabs/Conversation";
import Commands from "./tabs/Commands";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGroup,
  faPaperPlane,
  faClone,
} from "@fortawesome/free-solid-svg-icons";
import Context from "../../../../../context/Context";

interface Props {
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;

  templates: Template[];
  mode: ThianaAssistantMode;
}

export default function LeftSideWrapper(props: Props) {
  const [tab, setTab] = useState<string>("conversation");
  const { isASRProcessing } = useContext(Context);

  return (
    <Flex vertical gap={8} flex={1}>
      <div style={{ width: "100%" }}>
        <Segmented
          disabled={isASRProcessing}
          block={true}
          options={[
            {
              value: "conversation",
              label: "Conversation",
              icon: <FontAwesomeIcon icon={faUserGroup} />,
            },
            {
              value: "commands",
              label: "Commandes",
              icon: <FontAwesomeIcon icon={faPaperPlane} />,
            },
            {
              value: "shortcuts",
              label: "Raccourcis",
              icon: <FontAwesomeIcon icon={faClone} />,
            },
          ]}
          onChange={(value: string) => {
            setTab(value);
          }}
        />
      </div>
      <Flex
        vertical
        flex={1}
        style={{
          boxSizing: "border-box",
          height: "calc(100vh - 254px)",
        }}
      >
        {tab === "conversation" ? (
          <Conversation
            SOCKET_ASR={props.SOCKET_ASR}
            mode={props.mode}
            templates={props.templates}
          />
        ) : tab === "commands" ? (
          <Commands SOCKET_ASR={props.SOCKET_ASR} />
        ) : (
          <Shortcuts />
        )}
      </Flex>
    </Flex>
  );
}
