import { useContext, useEffect, useState } from "react";
import Context from "../../../context/Context";
import SideMenu from "../menu/SideMenu";
import {
  ReportOutput,
  Report as ThianaReport,
} from "@thiana/api-thiana-client";
import { WarningFilled } from "@ant-design/icons";
import { Modal, message, Flex } from "antd";
import { isChrome, isSafari, isFirefox } from "react-device-detect";
import { Template } from "../../../types/Templates";
import { ThianaPagination } from "../../../types/Pagination";
import useReports from "../../../hooks/useReports";
import useTranscriptionsRecoveries, {
  TranscriptionLocalStorage,
} from "../../../hooks/useTranscriptionsRecoveries";
import { getTranscriptionRecoveryByID } from "../../../api/api";
import { apiReports } from "../../../api-configuration/Configuration";
import { useParams } from "react-router-dom";

interface Props {
  children: JSX.Element;
  reports: ThianaReport[];
  setReports: React.Dispatch<React.SetStateAction<ThianaReport[]>>;
  SOCKET_ASR: React.MutableRefObject<WebSocket | undefined>;
  templates: Template[];
  setTemplates: React.Dispatch<React.SetStateAction<Template[]>>;
}

export default function Layout(props: Props) {
  const { isMenuHidden, updateIsMenuHidden, updateCurrentReport } =
    useContext(Context);
  const [paginationReports, setPaginationReports] = useState<ThianaPagination>({
    items: 40,
    currentPage: 1,
    nbPages: 1,
  });
  // useContext
  const { theme } = useContext(Context);
  // useReports
  let { fetchReports, createReport } = useReports({
    paginationReports: paginationReports,
    setPaginationReports: setPaginationReports,
  });
  const [messageApi, contextHolder] = message.useMessage();
  const { reportID } = useParams();

  const {
    getTranscriptionIDInLocalStorageByReportID,
    getTranscriptionsWithoutReportIDInLocalStorage,
    removeTranscriptionFromLocalStorage,
  } = useTranscriptionsRecoveries();

  // Ce useEffect récupère l'état du menu dans le navigateur
  useEffect(() => {
    let res = localStorage.getItem("isMenuHidden");
    if (res === "false") updateIsMenuHidden(false);
    else if (res === "true") updateIsMenuHidden(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ce useEffect permet de garder l'état du menu dans le navigateur
  useEffect(() => {
    localStorage.setItem("isMenuHidden", JSON.stringify(isMenuHidden));
  }, [isMenuHidden]);

  useEffect(() => {
    (async () => {
      await fetchReports();
      // Si il y a un id, on essaye de recover la transcription en erreur associé
      if (reportID) tryToRecoverTranscriptionByReportID(reportID);
      // Si il n'y a pas d'id, on essaye de recover toutes les transcriptions en erreurs
      else tryToRecoverTranscriptions();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationReports.currentPage, reportID]);

  const tryToRecoverTranscriptionByReportID = async (reportID: string) => {
    let transcriptionLS: TranscriptionLocalStorage | undefined =
      getTranscriptionIDInLocalStorageByReportID(reportID);
    if (transcriptionLS) {
      let recoveryResponse: { transcription: string };
      let response = await getTranscriptionRecoveryByID(
        transcriptionLS.transcriptionID
      );
      if (response.status === 200) {
        recoveryResponse = await response.json();
        // Update du report avec la transcription et titre (par défaut = à la date via le timestamp)
        let getReportOutput: ReportOutput;
        const response2 = await apiReports.reportGetRaw({
          id: reportID,
        });
        if (response2.raw.status === 200) {
          let reportOutput: ReportOutput;
          getReportOutput = await response2.raw.json();
          const response3 = await apiReports.reportUpdateRaw({
            id: reportID,
            reportInput: {
              ...getReportOutput.data[0],
              transcription:
                getReportOutput.data[0]?.transcription +
                recoveryResponse.transcription,
              transcriptions: [{ id: transcriptionLS.transcriptionID }],
            },
          });
          if (response3.raw.status === 200) {
            messageApi.success("Votre transcription a pu être récupérée.", 5);
            removeTranscriptionFromLocalStorage(
              transcriptionLS.transcriptionID
            );
            reportOutput = await response3.raw.json();
            updateCurrentReport(reportOutput.data[0]);
          }
        }
      }
    }
  };

  const tryToRecoverTranscriptions = async () => {
    let transcriptions = getTranscriptionsWithoutReportIDInLocalStorage();
    if (transcriptions && transcriptions.length > 0) {
      transcriptions.forEach(
        async (transcriptionLS: TranscriptionLocalStorage) => {
          let recoveryResponse: { transcription: string };
          let response = await getTranscriptionRecoveryByID(
            transcriptionLS.transcriptionID
          );
          if (response.status === 200) {
            recoveryResponse = await response.json();
            // Crée un nouveau report avec la transcription et titre (par défaut = à la date via le timestamp)
            let reportCreated = await createReport({
              transcription: recoveryResponse.transcription,
              title: transcriptionLS.timestamp
                ? new Date(transcriptionLS.timestamp).toLocaleString()
                : new Date().toLocaleString(),
              transcriptions: [{ id: transcriptionLS.transcriptionID }],
            });
            // Si le report a bien été crée
            if (reportCreated) {
              messageApi.success("Votre transcription a pu être récupérée.", 5);
              // On met à jour le currentReport
              updateCurrentReport(reportCreated);
              removeTranscriptionFromLocalStorage(
                transcriptionLS.transcriptionID
              );
            }
          }
        }
      );
    }
  };

  const ModalBrowser = () => (
    <Modal
      onCancel={() => {}}
      onOk={() => {}}
      footer={<></>}
      closable={false}
      //@ts-ignore
      open={!isFirefox && !isChrome && !isSafari && !navigator.brave} // navigator.brave existe uniquement sur Brave, sinon undefined
      title={
        <div>
          <WarningFilled style={{ color: "orange", marginRight: 5 }} />
          Thiana Assistant n'est pas compatible avec ce navigateur.
        </div>
      }
    >
      Pour le moment, Thiana Assistant est disponible sur <b>
        Google Chrome
      </b>, <b>Safari</b>, <b>Firefox</b>, ou <b>Brave</b> uniquement. Veuillez
      changer de navigateur pour utiliser l'application dans les meilleures
      conditions.
    </Modal>
  );

  return (
    <Flex
      style={{
        flexDirection: "row",
        // gap: isMenuHidden ? 0 : 16,
        height: "100vh",
        // overflow: "hidden",
        boxSizing: "border-box",
        backgroundColor: theme.contentBackgroundColor,
      }}
    >
      {contextHolder}
      <ModalBrowser />
      <div
        className="menu-transition"
        style={{ width: !isMenuHidden ? 320 : 0 }}
      >
        {!isMenuHidden ? (
          <SideMenu
            paginationReports={paginationReports}
            setPaginationReports={setPaginationReports}
            SOCKET_ASR={props.SOCKET_ASR}
            templates={props.templates}
            setTemplates={props.setTemplates}
          />
        ) : null}
      </div>
      <Flex
        flex={1}
        style={{
          boxSizing: "border-box",
          padding: 8,
          backgroundColor: theme.contentBackgroundColor,
        }}
      >
        {props.children}
      </Flex>
    </Flex>
  );
}
