import { ThianaAssistantMode } from "../types/Reports";

export type TranscriptionLocalStorage = {
  transcriptionID: string;
  timestamp: number;
  reportType: ThianaAssistantMode;
  reportID?: string;
};
export default function useTranscriptionsRecoveries() {
  const addTranscriptionIntoLocalStorage = (
    transcriptionID: string,
    reportType: ThianaAssistantMode,
    reportID?: string
  ) => {
    let transcriptions: TranscriptionLocalStorage[];
    let res = localStorage.getItem("Thiana.Transcriptions");
    try {
      if (res && Array.isArray(res)) {
        transcriptions = JSON.parse(res);
        transcriptions.push({
          transcriptionID,
          timestamp: new Date().getTime(),
          reportID,
          reportType,
        });
        localStorage.setItem(
          "Thiana.Transcriptions",
          JSON.stringify(transcriptions)
        );
      } else {
        localStorage.setItem(
          "Thiana.Transcriptions",
          JSON.stringify([
            {
              transcriptionID,
              timestamp: new Date().getTime(),
              reportID,
              reportType,
            },
          ])
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeTranscriptionFromLocalStorage = (transcriptionID: string) => {
    let transcriptions: TranscriptionLocalStorage[];
    let res = localStorage.getItem("Thiana.Transcriptions");
    try {
      if (res) {
        transcriptions = JSON.parse(res);
        if (Array.isArray(transcriptions)) {
          let index = transcriptions.findIndex(
            (transcriptionInError: TranscriptionLocalStorage) =>
              transcriptionID === transcriptionInError.transcriptionID
          );
          if (index !== -1) {
            transcriptions.splice(index, 1);
            localStorage.setItem(
              "Thiana.Transcriptions",
              JSON.stringify(transcriptions)
            );
          }
        } else throw new Error("Erreur : transcriptions n'est pas un Array");
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Cette fonction permet de retrouver (dans le localStorage) la transcriptionID à partir du reportID
   * @param reportID
   * @returns
   */
  const getTranscriptionIDInLocalStorageByReportID = (reportID: string) => {
    let transcriptions: TranscriptionLocalStorage[];
    let res = localStorage.getItem("Thiana.Transcriptions");
    try {
      if (res) {
        transcriptions = JSON.parse(res);
        if (Array.isArray(transcriptions)) {
          let index = transcriptions.findIndex(
            (transcription: TranscriptionLocalStorage) =>
              transcription.reportID === reportID
          );
          // On retourne l'id de la transcription en erreur
          return transcriptions[index];
        } else throw new Error("Erreur : transcriptions n'est pas un Array");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTranscriptionsWithoutReportIDInLocalStorage = () => {
    let transcriptions: TranscriptionLocalStorage[];
    let res = localStorage.getItem("Thiana.Transcriptions");
    try {
      if (res) {
        transcriptions = JSON.parse(res);
        if (Array.isArray(transcriptions)) {
          transcriptions = JSON.parse(res);
          transcriptions = transcriptions.filter(
            (transcription: TranscriptionLocalStorage) =>
              !transcription.reportID
          );

          // On retourne l'id de la transcription en erreur
          return transcriptions;
        } else throw new Error("Erreur : transcriptions n'est pas un Array");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    addTranscriptionIntoLocalStorage,
    removeTranscriptionFromLocalStorage,
    getTranscriptionIDInLocalStorageByReportID,
    getTranscriptionsWithoutReportIDInLocalStorage,
  };
}
