import { ThemeConfig, theme } from "antd";
import { ThianaAssistantMode } from "../types/Reports";
import { ThianaAssistantTheme } from "../types/Theme";

export default function useDarkTheme(mode: ThianaAssistantMode) {
  const { darkAlgorithm } = theme;

  const darkTheme: ThemeConfig | undefined = {
    algorithm: darkAlgorithm,
    token: {
      colorPrimary: mode === "conversation" ? "#6240dc" : "#2793f2",
    },
    components: {
      Popover: {
        colorBorder: "red",
      },
    },
  };

  const specificDarkThemeColors: ThianaAssistantTheme = {
    colorStatement: "#2793f2",
    colorConversation: "#6240dc",
    label: "dark",
    sideBackgroundColor: "#141414",
    contentBackgroundColor: "#1f1f1f",
    iconColor: "white",
    textColor: "white",
  };

  return { darkTheme, specificDarkThemeColors };
}
